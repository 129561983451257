import React from 'react'
import shortid from 'shortid'
import CookieService from 'services/cookie.service'
import { get, isEqual, cloneDeep } from 'lodash'

import { store } from 'redux/store'

import { SearchOptions } from 'services/apiTypes/card.types'

import { ARENA_PRIOIRTY_FORMATS, CardType, EDH_FORMATS, FORMAT, HUMAN_GAME } from 'types/deck'

export type SearchViews = 'tab' | 'tabSelection' | 'history'

export type ScryfallSearchMeta = { query: string; smartFilters: boolean }
export type ArchidektSearchMeta = SearchOptions
export type EdhRecsMeta = {}
export type ArchidektRecsMeta = {}
export type ManabaseGeneratorMeta = {}
export type SpellbookComboMeta = {}
export type OtherMeta = {}

export type TabType =
  | 'scrySearch'
  | 'archidektSearch'
  | 'edhRecs'
  | 'archidektRecs'
  | 'other'
  | 'manabaseGenerator'
  | 'spellbookCombo'

export type Meta =
  | ScryfallSearchMeta
  | ArchidektSearchMeta
  | EdhRecsMeta
  | ArchidektRecsMeta
  | OtherMeta
  | ManabaseGeneratorMeta
  | SpellbookComboMeta

export type Tab = {
  id: string
  noAutoSearch: boolean
  scrollHeight: number
} & (
  | { meta: ScryfallSearchMeta; type: 'scrySearch' }
  | { meta: ArchidektSearchMeta; type: 'archidektSearch' }
  | { meta: EdhRecsMeta; type: 'edhRecs' }
  | { meta: ArchidektRecsMeta; type: 'archidektRecs' }
  | { meta: ManabaseGeneratorMeta; type: 'manabaseGenerator' }
  | { meta: OtherMeta; type: 'other' }
  | { meta: SpellbookComboMeta; type: 'spellbookCombo' }
)

export type TabProps = {
  tab: Tab
  cards: CardType[]
  next: string
  onTabUpdate: (updatedTab: Tab) => void
  onCardSelect: (cardId: string, viewEditions?: boolean) => void
  onCardUpdated: (currentId: string, updatedCard: CardType) => void
  onResultsChange: (cards: CardType[]) => void
  onNextChange: (nextUrl: string) => void
  onDragStart?: () => void
  onDragStop?: () => void
  isSearchLocked?: boolean
  className?: string
  style?: React.CSSProperties
  hidden?: boolean
  draggable?: boolean
}

export const generateTabState: Record<TabType, (currentId?: string) => Tab> = {
  scrySearch: (currentId?: string) => ({
    type: 'scrySearch',
    meta: { query: '', smartFilters: true },
    id: currentId ?? shortid.generate(),
    noAutoSearch: false,
    scrollHeight: 0,
  }),
  archidektSearch: (currentId?: string) => ({
    type: 'archidektSearch',
    meta: generateArchidektSearchState(),
    id: currentId ?? shortid.generate(),
    noAutoSearch: false,
    scrollHeight: 0,
  }),
  edhRecs: (currentId?: string) => ({
    type: 'edhRecs',
    meta: {},
    id: currentId ?? shortid.generate(),
    noAutoSearch: false,
    scrollHeight: 0,
  }),
  archidektRecs: (currentId?: string) => ({
    type: 'archidektRecs',
    meta: {},
    id: currentId ?? shortid.generate(),
    noAutoSearch: false,
    scrollHeight: 0,
  }),
  other: (currentId?: string) => ({
    type: 'other',
    id: currentId ?? shortid.generate(),
    noAutoSearch: true,
    meta: {},
    scrollHeight: 0,
  }),
  manabaseGenerator: (currentId?: string) => ({
    type: 'manabaseGenerator',
    meta: {},
    id: currentId ?? shortid.generate(),
    noAutoSearch: false,
    scrollHeight: 0,
  }),
  spellbookCombo: (currentId?: string) => ({
    type: 'spellbookCombo',
    meta: {},
    id: currentId ?? shortid.generate(),
    noAutoSearch: false,
    scrollHeight: 0,
  }),
}

export const generateArchidektSearchState = (providedOptions: SearchOptions = {}): ArchidektSearchMeta => {
  const { deck } = store.getState()

  const formatLegality = deck.format

  const isArenaOnly = ARENA_PRIOIRTY_FORMATS.includes(formatLegality)
  const isEdh = EDH_FORMATS.includes(formatLegality)

  // Priority goes: deck game (if set) => account settings preferance => paper
  // All of this will be overriden for arena only formats
  const game: number = deck.game || get(CookieService.get('settings'), 'searchGame') || null

  return {
    ...cloneDeep(defaultArchidektSearch), // The form components modify the sub objects so this needs to be cloned so as not to mutate the subobjects of the default
    colorIdentity: isEdh, // default to identity for edh formats
    formatLegality,
    game: isArenaOnly ? HUMAN_GAME.Arena : game,
    includeTokens: true,
    includeEmblems: true,
    includeArtCards: true, // "The Initiative // Undercity" is an art card (ughghg)
    ...providedOptions,
  }
}

export const defaultArchidektSearch: SearchOptions = {
  name: '',
  colors: {
    White: true,
    Blue: true,
    Black: true,
    Red: true,
    Green: true,
    Colorless: true,
  },
  andcolors: false,
  colorIdentity: false,
  rarity: {
    common: true,
    uncommon: true,
    rare: true,
    mythic: true,
    special: true,
  },
  editions: [],
  superTypes: [],
  types: [],
  subTypes: [],
  oracleText: '',

  cmc: '',
  gtecmc: false,
  ltecmc: false,

  manaCost: '',

  power: '',
  gtepower: false,
  ltepower: false,

  toughness: '',
  gtetoughness: false,
  ltetoughness: false,

  flavor: '',
  artist: '',
  lore: '',
  orderBy: CookieService.get('archidekt-search-orderby') ?? 'oracleCard__name',
  formatLegality: FORMAT.CUSTOM,
  // Not using account settings service here to avoid a circular dependancy
  game: get(CookieService.get('settings'), 'searchGame') || HUMAN_GAME.Paper,
  // weird bit flip thing happening because we're negating the default value
  // We allow the default of the `get` to be true, so it'll be false once negated
  allEditions: !get(CookieService.get('settings'), 'searchUnique', true),
  collection: 0,
  collectorNumber: '',

  includeDigital: true,
  bracketInfo: undefined,

  page: 1,
}

export const calculateArchidektSearchDiff = (searchOptions: ArchidektSearchMeta): string => {
  const ingoredKeys = ['colors', 'rarity', 'formatLegality']

  let searchChanges = ''

  for (const [key, value] of Object.entries(searchOptions)) {
    if (ingoredKeys.includes(key)) continue

    // @ts-ignore - ts is wrong here key is keyof ArchidektSearchMeta due to arguement type
    // if they're equal, no changes have been made, move on
    if (isEqual(value, defaultArchidektSearch[key])) continue

    // If we have any changes already, add a +
    if (searchChanges) searchChanges += '+ '

    searchChanges += `${key}: ${value} `
  }

  return searchChanges
}

export type LockSizes = number

// Returns the predetermined width of the locked search given it's "lock size"
export const widthViaLockSize = (lockSize: LockSizes) => {
  if (lockSize === 1) return 402
  if (lockSize === 2) return 635
  if (lockSize === 3) return 877
  if (lockSize === 4) return 1125
  if (lockSize === 5) return 1400

  return 'auto'
}

export const getMaxLockSize = (): LockSizes => {
  if (typeof window === 'undefined') return 1

  const viewPortWidth = window.innerWidth

  // These numbers are largely arbirary, and have been created by playing with the UI
  // If we feel like the tolerances are too much/ little, we can change them
  if (viewPortWidth < 1200) return 1
  if (viewPortWidth < 1626) return 2
  if (viewPortWidth < 1892) return 3
  if (viewPortWidth < 2139) return 4
  if (viewPortWidth < 2410) return 5

  return 5
}

export const generateTabDOMId = (id: string) => `tab-search-input-${id}`

const archidektSearchOrderby = 'archidekt-search-orderby'
export const setArchidektSearchOrderBy = (orderBy: string) => CookieService.set(archidektSearchOrderby, orderBy)
export const getArchidektSearchOrderBy = () => CookieService.get(archidektSearchOrderby) ?? 'oracleCard__name'
