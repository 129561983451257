import PhatDropdown from 'components/formElements/PhatDropdown'

import { GameType } from 'types/deck'

import styles from './gameOptionDropdown.module.scss'

type Props = {
  value?: number | GameType | null
  onChange: (value: number | null) => void
}

const SortingDropdown = ({ value, onChange }: Props) => {
  return (
    <div>
      <label>Game filter (paper, mtgo, arena):</label>
      {/* @ts-ignore */}
      <PhatDropdown
        triggerWrapperClassName={styles.fullWidth}
        value={value}
        placeholder="None"
        options={[
          { onClick: () => onChange(null), label: 'None', id: undefined },
          { onClick: () => onChange(1), label: 'Paper', id: '1' },
          { onClick: () => onChange(2), label: 'MTGO', id: '2' },
          { onClick: () => onChange(3), label: 'Arena', id: '3' },
        ]}
      />
    </div>
  )
}

export default SortingDropdown
